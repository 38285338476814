var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.closetAvatars && _vm.closetAvatars.length)?_c('div',{staticClass:"d-flex flex-column gap-3 gap-sm-4 hide-scroll avatars-frame",class:{
    'pa-4': !_vm.$vuetify.breakpoint.smOnly,
    'pa-2 mt-6': _vm.$vuetify.breakpoint.smOnly,
  }},_vm._l((_vm.totalRows),function(rowItem,rowIndex){return _c('div',{key:rowIndex,staticClass:"d-flex table-container gap-3 gap-sm-4"},_vm._l((_vm.itemPerRow),function(i){return _c('div',{key:i,staticClass:"overflow-hidden table-cell",class:{
        'highlight-border':
          _vm.popoStore.selectedClosetAvatar &&
          _vm.closetAvatars[rowIndex * _vm.itemPerRow + i - 1] &&
          _vm.popoStore.selectedClosetAvatar.id === _vm.closetAvatars[rowIndex * _vm.itemPerRow + i - 1].id,
      }},[(rowIndex * _vm.itemPerRow + i <= _vm.closetAvatars.length)?_c('div',{staticClass:"pa-2 px-0 primary darken-1",on:{"click":function($event){return _vm.selectAvatar(rowIndex * _vm.itemPerRow + i - 1)}}},[_c('v-img',{staticClass:"avatar-image",attrs:{"src":_vm.closetAvatars[rowIndex * _vm.itemPerRow + i - 1].avatar.url,"cover":""}})],1):_vm._e()])}),0)}),0):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }