
































import { popoStore } from '@/stores/popo-store'
import { Observer } from 'mobx-vue'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Observer
@Component({
  components: {},
})
export default class extends Vue {
  popoStore = popoStore
  @Prop({ default: () => [] }) closetAvatars

  selectAvatar(index) {
    this.popoStore.changeselectedClosetAvatar(index)
  }

  get itemPerRow() {
    return this.$vuetify.breakpoint.lgAndUp ? 4 : 3
  }
  get totalRows() {
    return Math.ceil(this.closetAvatars.length / this.itemPerRow)
  }
}
